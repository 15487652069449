import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { graphql } from 'gatsby';
import queryString from 'query-string';
import { useLocation } from '@reach/router';
import PageBody from '../../components/pages/page/PageBody';
import { IInsightThumbnail, IPage } from '../../components/pages/page/types';
import { client } from '../../utils/client';

const query = `*[_type == "page" && _id == $pageId]{
  title,
  hasSubmenu,
  showTwitterSection,
  seoTitle,
  seoDescription,
  carouselItems[]{
    _key,
    heading,
    "_rawBody": body,
  },
  infoBanner,
  showSectionSlider,
  slides[]{
    _key,
    title,
    setWhiteColorText,
    description,
    tag,
    buttonText,
    buttonLink,
    "image": {
      "asset": {
        "fluid": {
          "src": image.asset->url,
        }
      }
    }
  },
  carouselQuotes[]{
    heading,
    quotes[]{
      _key,
      authorName,
      positionName,
      quote,
      title,
      "image": {
          "asset": {
            "fluid": {
              "src": image.asset->url,
              "aspectRatio": image.asset->metadata.dimensions.aspectRatio
            }
          }
        }
    }
  },
	sections[]{
    _type,
    _key,
    heading,
    title,
    videoUrl,
    isAutoplay,
    text,
    button,
    "_rawBody": body,
    contactCards,
    "video": {
      "asset": {
        "url": video.asset->url
      }
    },
    "mainImage": {
      "asset": {
        "fluid": {
          "src": mainImage.asset->url,
          "aspectRatio": mainImage.asset->metadata.dimensions.aspectRatio
        }
      }
    },
    "placeholderImage": {
        "asset": {
          "fluid": {
            "src": placeholderImage.asset->url,
            "aspectRatio": placeholderImage.asset->metadata.dimensions.aspectRatio
          }
        }
      },
    "caseStudies": caseStudies[]->{
      "id": _id,
      darkBackground,
      clientName,
      title,
      slug,
      "mainVideo": {
        "asset": {
          "url": mainVideo.asset->url
        }
      },
      "mainImage": {
        "asset": {
          "fluid": {
            "src": mainImage.asset->url,
            "aspectRatio": mainImage.asset->metadata.dimensions.aspectRatio
          }
        }
      },
    },
    "teamMembers": teamMembers[]->{
      "id": _id,
      name,
      position,
      email,
      "_rawDescription": description,
      "mainImage": {
        "asset": {
          "fluid": {
            "src": mainImage.asset->url,
            "aspectRatio": mainImage.asset->metadata.dimensions.aspectRatio
          }
        }
      },
    },
    shouldIgnoreSelection,
    "insights": insights[]->{
      "id": _id,
      title,
      description,
      imageUrl,
      author,
      slug,
      date,
    },
    list,
    listItems,
    "pagesList": pagesList[]{
      _key,
      "_rawBody": body,
      "image": {
        "asset": {
          "fluid": {
            "src": image.asset->url,
            "aspectRatio": image.asset->metadata.dimensions.aspectRatio
          }
        }
      },
      isWorkRelated,
      "url": {
        "slug": url->slug
      },
      buttonText
    },
    isReverseColumn,
    "mediaItem": mediaItem[]{
      _type,
      _key,
      videoUrl,
      isAutoplay,
      "video": {
        "asset": {
          "url": video.asset->url
        }
      },
      "placeholderImage": {
        "asset": {
          "fluid": {
            "src": placeholderImage.asset->url,
            "aspectRatio": placeholderImage.asset->metadata.dimensions.aspectRatio
          }
        }
      },
      "asset": {
        "fluid": {
          "src": asset->url,
          "aspectRatio": asset->metadata.dimensions.aspectRatio
        }
      }
    },
    "jobs": jobs[]->{
      "id": _id,
      description,
      name,
      slug,
    },
    mapList[]{
      _key,
      title,
      address,
      phoneNumber,
      zoom,
      mapUrl,
      pinPositionX{
        mobile,
        tablet,
        desktop
      },
      pinPositionY{
        mobile,
        tablet,
        desktop
      },
      "image": {
        "asset": {
          "fluid": {
            "src": image.asset->url,
            "aspectRatio": image.asset->metadata.dimensions.aspectRatio
          }
        }
      },
    },
  },
}`;

type IPreviewPageProps = {
  data: {
    allSanityInsightShortcut: {
      nodes: IInsightThumbnail[];
    };
  };
};

const PreviewPage = ({ data }: IPreviewPageProps): React.ReactNode => {
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({} as IPage);
  const location = useLocation();

  const pageId = useMemo(() => queryString.parse(location.search)?.pageId, [
    location,
  ]);

  // WE fetch that in node for original pages but for preview we have to use page query
  const lastInsights = data?.allSanityInsightShortcut?.nodes;

  const fetchData = useCallback(async () => {
    const backendData: IPage[] = await client.fetch(query, { pageId });

    if (backendData[0]) setPageData(backendData[0]);
    setLoading(false);
  }, [pageId]);

  useEffect(() => {
    const subscribeData = client
      .listen(query, { pageId }, { visibility: 'query', includeResult: false })
      .subscribe(() => {
        setLoading(true);
        fetchData();
      });
    return () => subscribeData.unsubscribe();
  }, [fetchData, pageId]);

  useEffect(() => {
    if (pageId) fetchData();
  }, [fetchData, pageId]);

  if (loading && !Object.keys(pageData).length) {
    return <div>Loading ...</div>;
  }

  return <PageBody data={{ page: pageData }} pageContext={{ lastInsights }} />;
};

export default PreviewPage;

export const pageQuery = graphql`
  query {
    allSanityInsightShortcut(limit: 4, sort: { order: DESC, fields: date }) {
      nodes {
        imageUrl
        title
        description
        date(formatString: "DD.MM.YY")
        author
        id
        slug {
          current
        }
      }
    }
  }
`;
